<template>
  <div class="rounded border px-2 py-3 mb-2">
    <div
      class="caret-after d-flex align-items-center cursor-pointer"
      :class="{'loaded' : model}"
      @click="input(icon)
    ">
      <img :src="require('@/assets/img/icon_' + icon + '.png')" width="24" alt="">
      <p class="pl-2 mb-0" :class="{ 'd-none': !label }">{{ placeholder }}</p>
    </div>
    <b-form-file
      :id="icon"
      class="d-none"
      accept="image/jpeg, image/png"
      :state="value ? Boolean(value) : null"
      :placeholder="label ? 'Select/drop file here...' : placeholder"
      drop-placeholder="Select/drop file here..."
      @input="handleFileChange"
      :required="required || false"
      :disabled="disabled || false"
    />
    <template v-if="model">
      <hr/>
      <img :src="model" class="rounded w-100" alt="">
    </template>
  </div>
</template>
<script>
export default {
  name: "MyInputFormFile",
  props: ["placeholder", "icon", "label", "value", "required", "disabled"],
  data() {
    return {
      model: null,
    }
  },
  mounted() {
    if (this.value && /^https:\/\//.test(this.value)) this.model = this.value
    else if (this.value) this.handleFileChange(this.value)
  },
  methods: {
    input(icon) {
      const el = document.getElementById(icon)
      if (el.disabled) this.$emit("clickfail")
      else el.click()
    },
    handleFileChange(event) {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        this.model = e.target.result
      }
      reader.readAsDataURL(event)
      this.$emit("input", event);
    },
  },
  watch: {
    value(val) {
      this.model = val
    }
  }
};
</script>
