<template>
  <div id="page">
    <section class="product-list-heading box-my-account mb-0">
      <div class="container-fluid">
        <div class="row">
          <ProfileNavbar :parentData="userData.full_name"></ProfileNavbar>
          <div id="edit-form" class="col-md-9 py-5 px-lg-5">
            <form @submit.prevent="handleSubmit" class="mt-4">
              <h2>Edit Profile</h2>
              <div class="row">
                <div class="col-md-6">
                  <!-- Email (Readonly) -->
                  <MyInputFormText
                    id="email"
                    label="Email"
                    type="email"
                    v-model="$v.email.$model"
                    placeholder="Email"
                    :classComponent="{
                      'is-invalid-custom': $v.email.$error,
                    }"
                    :invalidMessage="[
                      !$v.email.required ? 'Butuh email kamu nih' : null,
                      !$v.email.email ? 'Masukan format email kamu' : null,
                    ]"
                    required="true"
                    readonly="true"
                    redAsterisk="true"
                  />
                  <!-- Phone Number -->
                  <MyInputFormText
                    id="phone-number"
                    label="Phone Number"
                    v-model="$v.phone.$model"
                    placeholder="Phone Number"
                    :classComponent="{
                      'is-invalid-custom': $v.phone.$error,
                    }"
                    :invalidMessage="[
                      !$v.phone.required
                        ? 'Butuh nomor telepon kamu nih'
                        : null,
                      !$v.phone.phonePattern
                        ? 'Nomor telepon kamu tidak valid :('
                        : null,
                      !$v.phone.minLength && $v.phone.phonePattern
                        ? `Nomor telepon kamu terlalu pendek nih, panjang teks minimum ${$v.phone.$params.minLength.min}.`
                        : null,
                      !$v.phone.maxLength && $v.phone.phonePattern
                        ? `Nomor telepon kamu terlalu panjang nih, panjang teks maksimum ${$v.phone.$params.maxLength.max}.`
                        : null,
                    ]"
                    redAsterisk="true"
                    phonePrefix="true"
                  />
                  <!-- Gender -->
                  <div class="form-group">
                    <div class="d-flex align-items-center">
                      <p class="mr-4">
                        <label>Gender <span class="text-danger">*</span></label>
                      </p>
                      <div class="styled-radio">
                        <label class="container d-flex align-items-center">
                          Male
                          <input
                            v-model="gender"
                            type="radio"
                            value="m"
                            name="gender"
                            required
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="styled-radio">
                        <label class="container d-flex align-items-center">
                          Female
                          <input
                            v-model="gender"
                            type="radio"
                            value="f"
                            name="gender"
                            required
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <!-- Full Name -->
                  <MyInputFormText
                    id="full-name"
                    label="Full Name"
                    v-model="$v.full_name.$model"
                    placeholder="Full Name"
                    :classComponent="{
                      'is-invalid-custom': $v.full_name.$error,
                    }"
                    :invalidMessage="[
                      !$v.full_name.required
                        ? 'Butuh nama lengkap kamu nih'
                        : null,
                      !$v.full_name.namePattern ? 'Masukin text yu!' : null,
                    ]"
                    redAsterisk="true"
                  />
                  <!-- Birthday -->
                  <MyInputFormText
                    id="birthday"
                    label="Birthday"
                    type="date"
                    v-model="$v.birth_date.$model"
                    placeholder="Birthday"
                    :classComponent="{
                      'is-invalid-custom': $v.birth_date.$error,
                    }"
                    :invalidMessage="[
                      !$v.birth_date.required
                        ? 'Butuh tanggal lahir kamu nih'
                        : null,
                      !$v.birth_date.maxValue
                        ? 'Usia kamu belum mencapai 17 thn :('
                        : null,
                    ]"
                    :max="getMaxBirthDate"
                    redAsterisk="true"
                  />
                </div>
              </div>
              <!-- // TODO: reseller data -->
              <template v-if="1 == 0">
                <h2>Business Partner Profile</h2>
                <div class="row">
                  <!-- Region -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="clearfix">
                        <div class="input-line">
                          <select
                            class="form-control-custom"
                            :class="{
                              'is-invalid-custom': $v.selectedProvince.$error,
                            }"
                            v-model="$v.selectedProvince.$model"
                            @change="
                              getDistrict(selectedProvince);
                              selectedDistrict = null;
                              selectedSubDistrict = null;
                            "
                          >
                            <option :value="null" disabled selected>
                              Select Province *
                            </option>
                            <option
                              v-for="(province, i) in provinceList"
                              :key="i"
                              :value="province.id"
                            >
                              {{ province.name }}
                            </option>
                          </select>
                          <div class="invalid-feedback text-right">
                            <p v-if="!$v.selectedProvince.required">
                              Butuh provinsi kamu nih
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- District -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="clearfix">
                        <div class="input-line">
                          <select
                            class="form-control-custom"
                            :class="{
                              'is-invalid-custom': $v.selectedDistrict.$error,
                            }"
                            v-model="$v.selectedDistrict.$model"
                            @change="
                              getSubDistrict(selectedDistrict);
                              selectedSubDistrict = null;
                            "
                          >
                            <option :value="null" selected disabled>
                              Select District *
                            </option>
                            <option
                              v-for="(district, i) in districtList"
                              :key="i"
                              :value="district.id"
                            >
                              {{ district.name }}
                            </option>
                          </select>
                          <div class="invalid-feedback text-right">
                            <p v-if="!$v.selectedDistrict.required">
                              Butuh daerah kamu nih
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Sub Disctrict -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="clearfix">
                        <div class="input-line">
                          <select
                            class="form-control-custom"
                            :class="{
                              'is-invalid-custom':
                                $v.selectedSubDistrict.$error,
                            }"
                            v-model="$v.selectedSubDistrict.$model"
                          >
                            <option :value="null" selected disabled>
                              Select Subdistrict *
                            </option>
                            <option
                              v-for="(subDistrict, i) in subDistrictList"
                              :key="i"
                              :value="subDistrict.id"
                            >
                              {{ subDistrict.name }}
                            </option>
                          </select>
                          <div class="invalid-feedback text-right">
                            <p v-if="!$v.selectedSubDistrict.required">
                              Butuh kecamatan kamu kamu nih
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Address -->
                  <div class="col-md-6">
                    <MyInputFormText
                      id="address"
                      label="Address"
                      v-model="$v.address.$model"
                      placeholder="Address"
                      :classComponent="{
                        'is-invalid-custom': $v.address.$error,
                      }"
                      :invalidMessage="[
                        !$v.address.required ? 'Butuh alamat kamu nih' : null,
                      ]"
                      redAsterisk="true"
                    />
                  </div>
                  <!-- Postal Code -->
                  <div class="col-md-6">
                    <MyInputFormText
                      id="postal-code"
                      label="Postal Code"
                      v-model="$v.postalCode.$model"
                      placeholder="Postal Code"
                      :maxlength="5"
                      :classComponent="{
                        'is-invalid-custom': $v.postalCode.$error,
                      }"
                      :invalidMessage="[
                        !$v.postalCode.required
                          ? 'Butuh kode postal kamu nih'
                          : null,
                        !$v.postalCode.numeric && $v.postalCode.required
                          ? 'Masukan nomor yu!'
                          : null,
                        !$v.postalCode.minLength
                          ? `Kode postal kamu terlalu pendek nih, panjang teks minimum ${$v.postalCode.$params.minLength.min}`
                          : null,
                      ]"
                      redAsterisk="true"
                    />
                  </div>
                  <!-- Bank Info -->
                  <div class="col-4">
                    <div class="form-group">
                      <div class="clearfix">
                        <div class="input-line">
                          <select
                            class="form-control-custom"
                            :class="{
                              'is-invalid-custom': $v.selectedBank.$error,
                            }"
                            v-model="$v.selectedBank.$model"
                            required
                          >
                            <option :value="null" selected disabled>
                              Bank *
                            </option>
                            <option
                              v-for="(bank, i) in bankReseller"
                              :key="i"
                              :value="bank.id"
                            >
                              {{ bank.name }}
                            </option>
                          </select>
                          <div class="invalid-feedback text-right">
                            <p v-if="!$v.selectedBank.required">
                              Butuh bank yang kamu gunakan nih
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Bank Account -->
                  <div class="col-8">
                    <MyInputFormText
                      id="bank-account"
                      label="Bank Account Number"
                      v-model="$v.bankAccount.$model"
                      placeholder="Bank Account"
                      :classComponent="{
                        'is-invalid-custom': $v.bankAccount.$error,
                      }"
                      :invalidMessage="[
                        !$v.bankAccount.required
                          ? 'Butuh rekening bank kamu nih'
                          : null,
                        !$v.bankAccount.numeric && $v.bankAccount.required
                          ? 'Masukan nomor yu!'
                          : null,
                      ]"
                      redAsterisk="true"
                    />
                  </div>
                  <hr />
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <h5 class="bold">VERIFY YOUR IDENTITY</h5>
                    <div class="verify-identity-desc">
                      We need you to submit the required document below to process the application. Please make sure that the photos are not blurry and have proper lighting.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <MyInputFormFile
                      icon="idcard"
                      class="w-100"
                      :value="imgIdCard"
                      :label="true"
                      :disabled="
                        this.resellerData.status_verify &&
                        imgIdCard != '' &&
                        typeof imgIdCard != 'object'
                      "
                      placeholder="Identification Card"
                      @input="imgIdCard = $event"
                      @clickfail="
                        popToast('You are already verified', 'danger')
                      "
                    />
                  </div>
                  <div class="col-md-6">
                    <MyInputFormFile
                      icon="selfie"
                      class="w-100"
                      :value="imgSelfie"
                      :label="true"
                      :disabled="
                        this.resellerData.status_verify &&
                        imgSelfie != '' &&
                        typeof imgSelfie != 'object'
                      "
                      placeholder="Photo"
                      @input="imgSelfie = $event"
                      @clickfail="
                        popToast('You are already verified', 'danger')
                      "
                    />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <h5 class="bold">Find Our Business Partner</h5>
                    <div class="find-our-bp-desc">Dapatkan lebih banyak peluang bisnis dengan fitur Find Our Business Partner (BP). Akun anda akan ditampilkan pada menu Find Our BP, sehingga pengguna dapat dengan mudah menghubungi anda untuk melakukan transaksi. Pastikan nomor HP anda yang tertera sudah benar dan terdaftar pada aplikasi Whatsapp.</div>
                    <div class="find-our-bp-feature mt-2">
                      <div>Aktifkan fitur Find Our BP</div>
                      <b-form-checkbox v-model="show" class="find-our-bp-switch" switch />
                    </div>
                  </div>
                </div>
              </template>
              <div class="row mt-3">
                <div class="col d-flex justify-content-center">
                  <div class="form-group">
                    <button type="submit" class="btn btn-wz-proceed">
                      Save Change
                      <font-awesome v-if="isLoading" icon="spinner" spin />
                    </button>
                  </div>
                </div>
              </div>
              <Accordion>
                <template #title>
                  <h2 class="text-danger">Request Delete Account</h2>
                </template>
                Mohon perhatikan bahwa setelah akun kamu dihapus, semua data di akun kamu seperti data identitas, orderan, Liz-Points, Alamat, dan data lainnya tidak dapat dipulihkan kembali<br/>
                <a href="#" @click="deactivateAccount">Klik di sini untuk melanjutkan</a>
              </Accordion>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toaster, regex } from "@/_helpers";
import { resellerService, addressService } from "@/_services";

import ProfileNavbar from "@/components/mains/ProfileNavbar.vue";
import MyInputFormText from "@/components/form/MyInputFormText";
import MyInputFormFile from "@/components/form/MyInputFormFile";
import Accordion from '@/components/bundling/Accordion';

import axios from "axios";
import moment from "moment";
import {
  required,
  requiredIf,
  email,
  numeric,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

// Regex for Validation Start
const namePattern = helpers.regex("namePattern", regex.namePattern);
const phonePattern = helpers.regex("phonePattern", regex.phonePattern);
// Regex for Validation End

export default {
  name: "EditProfile",
  components: {
    ProfileNavbar,
    MyInputFormText,
    MyInputFormFile,
    Accordion,
  },
  data() {
    return {
      // msg: [],
      email: null,
      phone: null,
      full_name: null,
      birth_date: null,
      gender: null,
      // Start reseller data
      provinceList: [],
      selectedProvince: null,
      districtList: [],
      selectedDistrict: null,
      subDistrictList: [],
      selectedSubDistrict: null,
      address: null,
      postalCode: null,
      bankReseller: [],
      selectedBank: null,
      bankAccount: null,
      imgIdCard: null,
      imgSelfie: null,
      show: false,
      // End reseller data
      isLoading: false,
      token: this.$store.getters.getUserToken,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    phone: {
      required,
      minLength: minLength(9),
      maxLength: maxLength(14),
      phonePattern,
    },
    full_name: {
      required,
      namePattern,
    },
    birth_date: {
      required,
      maxValue(date) {
        // max = 17 years ago
        let max = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
        max = moment.unix(max).toDate();
        date = new Date(date);
        if (date > max) return false;
        return true;
      },
    },
    // Business partner
    selectedProvince: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      // provinceIsValid,
    },
    selectedDistrict: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      // districtIsValid,
    },
    selectedSubDistrict: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      // subDisctrictIsValid,
    },
    address: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
    },
    postalCode: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      numeric,
      minLength: minLength(5),
    },
    selectedBank: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      // bankInfoIsValid,
    },
    bankAccount: {
      required: requiredIf(function () {
        return this.resellerData;
      }),
      numeric,
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    // Min age 17 years old
    getMaxBirthDate() {
      let res = new Date().setFullYear(new Date().getFullYear() - 17) / 1000;
      return moment.unix(res).format("YYYY-MM-DD");
    },
  },
  watch: {
    // phone(value) {
    //   const phoneRegex = /^((?:\+62|62)|0)[2-9]{1}[0-9]+$/;
    //   if (phoneRegex.test(value)) {
    //     this.msg["phone"] = "";
    //   } else {
    //     this.msg["phone"] = "Invalid Phone Number";
    //   }
    // },
  },
  mounted() {
    this.setUserData(this.userData);
    if (this.resellerData) {
      this.selectedProvince = this.resellerData.region.id;
      this.selectedDistrict = this.resellerData.district.id;
      this.selectedSubDistrict = this.resellerData.township.id;
      this.address = this.resellerData.address;
      this.postalCode = this.resellerData.postal_code;
      this.selectedBank = this.resellerData.bank_info.split("-")[0] || null;
      this.bankAccount = this.resellerData.bank_account;
      this.imgIdCard = this.resellerData.imageIdcardUrlLink;
      this.imgSelfie = this.resellerData.imageIdcardUrlHoldLink;
      this.show = this.resellerData.show;
      this.getProvinceList();
      this.getBankResellerList();
    }
  },
  methods: {
    popToast(message, variant) {
      toaster.make(message, variant);
    },
    async deactivateAccount() {
      try {
        const res = await this.$api.user.deactivateAccount()
        toaster.make(res.data.message, res.status === 200 ? 'success' : 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    handleSubmit() {
      try {
        // this.submitted = true;
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$error) {
          toaster.make("Please fill the form correctly", "danger");
          const errors = Object.keys(this.$v)
            .map((el) => {
              if (!/^\$/.test(el))
                return {
                  form: el,
                  data: this.$v[el],
                };
            })
            .filter((el) => el && el.data.$invalid);
          console.error("Invalid form data", errors);
          this.isLoading = false;
          this.scrollToTop();
        } else {
          this.updateProfile();
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
    async updateProfile() {
      const data = {
        email: this.email,
        full_name: this.full_name,
        gender: this.gender,
        birth_date: this.birth_date,
        phone: /\+62/.test(this.phone) ? this.phone : "+62" + this.phone,
      };
      let updateResellerResponse = {};
      let updateMemberResponse = {};
      if (this.resellerData) {
        data.region_id = this.selectedProvince;
        data.district_id = this.selectedDistrict;
        data.township_id = this.selectedSubDistrict;
        data.village_id = null;
        data.address = this.address;
        data.postal_code = this.postalCode;
        data.bank_reseller = this.selectedBank;
        data.bank_account = this.bankAccount;
        data.show = this.show;
        const form = new FormData();
        form.append("data", JSON.stringify(data));
        if (this.imgIdCard) form.append("image_idcard", this.imgIdCard);
        if (this.imgSelfie) form.append("image_idcard_hold", this.imgSelfie);
        updateResellerResponse = await resellerService.updateReseller(form);
      } else {
        updateMemberResponse = await axios.patch(
          this.apiUrl + "users/profile/update",
          data,
          {
            headers: {
              api_key: this.apiKey,
              token: this.token,
              "Content-Type": "application/json",
            },
          }
        );
      }
      if (
        updateMemberResponse.status === 200 ||
        updateResellerResponse.status === 200
      ) {
        toaster.make("User details updated", "success");
        this.$router.push("/profile");
      } else {
        if (updateMemberResponse.status === 400) {
          toaster.make(updateMemberResponse.data.message, "danger");
        } else if (updateResellerResponse.status === 400) {
          toaster.make(updateResellerResponse.data.message, "danger");
        }
      }
    },
    setUserData(data) {
      this.email = data.email;
      this.phone = this.phone = /\+62/.test(data.phone)
        ? data.phone.replace(/\+62/, "")
        : data.phone;
      this.full_name = data.full_name;
      this.birth_date = data.birth_date;
      this.gender = data.gender.toLowerCase();
    },
    async getProvinceList() {
      this.provinceList = [];
      try {
        const response = await addressService.getProvinceList();
        if (response.status === 200) {
          this.provinceList = response.data.data;
          this.getDistrict(this.selectedProvince);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDistrict(id) {
      this.districtList = [];
      this.subDistrictList = [];
      try {
        const response = await addressService.getDistrictList(id);
        if (response.status === 200) {
          this.districtList = response.data.data;
          this.getSubDistrict(this.selectedDistrict);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSubDistrict(id) {
      this.subDistrictList = [];
      try {
        const response = await addressService.getSubDistrictList(id);
        if (response.status === 200) {
          this.subDistrictList = response.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getBankResellerList() {
      this.bankReseller = [];
      try {
        const response = await resellerService.getBankResellerList();
        if (response.status === 200) {
          this.bankReseller = response.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    scrollToTop() {
      const yOffset = -70;
      const element = document.getElementById("edit-form");
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.img-reseller {
  max-width: 350px;
  max-height: 200px;
}
.verify-identity-desc, .find-our-bp-desc {
  font-size: 14px;
  color: #4f4f4f;
}
.find-our-bp-feature {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid black;
  border-radius: 4px;
}
.find-our-bp-switch :deep(.custom-control-input:checked ~ .custom-control-label::before) {
  border-color: #28a745;
  background-color: #28a745;
}
@media only screen and (max-width: 480px) {
  .img-reseller {
    max-width: 100%;
  }
}

</style>